<template>
  <div class="bigbox">
    <img class="img"
         src="../assets//images/tcar.png"
         alt="">
    <button class="btn"
            @click="go">我已了解，下一步</button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      valy: '',
    }
  },
  methods: {
    go () {
      window.location.href = "http://m.ebopark.com/index.php"
    }
  }
}
</script>

<style lang="scss" scoped>
.bigbox {
  position: relative;
  .img {
    width: 100%;
  }
  .btn {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 90%;
    height: 45px;
    margin: 0 5%;
    background: #264166;
    color: #fff;
    border: 0;
    border-radius: 10px;
  }
}
</style>